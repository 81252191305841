import React, { useState, useEffect, Fragment } from "react";

import {
  Grid,
  GridColumn,
  GridDetailRow,
  GridExpandChangeEvent,
} from "@progress/kendo-react-grid";

import styles from "./Log.module.scss";

import { Changeset } from "../../common/ddc/types";
import { LogProps } from "./types";
import { arrayToString } from "../../common/util";

const convertToString = (data: any) => {
  if (data === "" || data === undefined || data === null) {
    return `""`;
  } else if (typeof data === "string" || typeof data === "number") {
    return `${data}`;
  } else if (Array.isArray(data)) {
    return arrayToString(data);
  } else {
    return JSON.stringify(data);
  }
};

//@TODO Add RCA Label
const labelMap: { [key: string]: string | string[] } = {
  acolinCounterparties: "Acolin Counterparties",
  additionalMeasures: "Additional measures",
  arr: "ARR",
  comments: "Comments",
  reasonForRiskChange: "Reason for Change of Overall Rating",
  contracts: "Contracts",
  cooperationWithCounterparty: "Cooperation with Counterparty",
  counterpartyId: "Counterparty ID",
  counterpartyName: "Counterparty Name",
  ddcType: "DDC Type",
  domicile: "Domicile",
  domicileGroup: "Domicile Group",
  domicileISOCode: "Domicile ISO Code",
  dueDiligenceConducted: "Due Diligence Conducted",
  dueDiligenceDocuments: "Documents Used",
  frequency: "Next Review Due",
  links: "Links",
  nextDDDate: "Due Date",
  recommendation: "Recommendation",
  relationshipToOtherParties: "Relationship to Other Parties",
  services: "Services",
  status: "Status",
  submissionDate: "Reporting Date",
  typeOfDD: "Checklist Type",
  worldCheckOneConducted: "AML Check Conducted",
  wphgClassification: "WPHG Classification",
  // AML/CTF values
  amlCtfScore: "AML/CTF score",
  PEPUBO: "PEP UBO",
  dealingWithCustomersSubjectToSanctions:
    "Dealings with Customers Subject to Sanctions",
  informationRegardingOtherRegulatoryFailings:
    "Other Adverse Information Regarding Counterparty/UBO",
  informationRelatedToFinancialCrime: [
    "Adverse Information Related to Financial Crime Counterparty/UBO",
    "Adverse Information Related to Financial Crime Counterparty",
  ],
  onSanctionList: [
    "Counterparty/UBO on Sanction List",
    "Counterparty on Sanction List",
  ],
  opaqueShareholdingStructure: "Opaque Shareholding Structure",
  countryOfIncorporation: "Country of Incorporation of Counterparty:",
  countryOfResidence: [
    "Country of Residence UBO",
    "Country of Residence of Parent Company",
  ],
  nationalityUBO: "Nationality UBO",
  countriesOfBusinessActivities: "Countries of Business Activities",
  exemptFromUBOIdentification: "Exempt from UBO Identification", // version 1
  applicableCountryOfResidence: "Applicable for Country of Residence", // version 1
  UBO: "UBOs", // version 1
  riskConfigurationVersionDate: "Risk Template Configuration",
};

const prettify = (fieldName: string, isNewAmlCtfVersion = false) => {
  const label = labelMap[fieldName];
  if (label !== undefined) {
    if (typeof label === "string") {
      return label;
    } else {
      const labelIndex = isNewAmlCtfVersion ? 1 : 0;
      return label[labelIndex];
    }
  } else {
    return fieldName;
  }

  // const prettified = labelMap[fieldName];
  // if (prettified !== undefined) {
  //   return prettified;
  // } else {
  //   return fieldName;
  // }
};

class LogDetail extends GridDetailRow {
  render() {
    const changeset = this.props.dataItem.changeset as Changeset;
    const status = this.props.dataItem.status;
    const isNewAmlCtfVersion: boolean = this.props.dataItem.isNewAmlCtfVersion;

    // If cahngeset entries don't contain status value, adjust the
    // detail view to fitting number of columns
    let rowStyle =
      status === null || status === ""
        ? styles.twoColumns
        : styles.threeColumns;

    return (
      <div className={styles.editLogChangeset}>
        <dl className={rowStyle}>
          {Object.keys(changeset).map((field) => (
            <Fragment key={field}>
              <dt>{prettify(field, isNewAmlCtfVersion)}</dt>
              <dd>
                Previous value{" "}
                <b className={styles.green}>
                  {convertToString(changeset[field].previousValue)?.trim()}
                </b>{" "}
                was changed to{" "}
                <b className={styles.green}>
                  {convertToString(changeset[field].newValue)?.trim()}
                </b>
              </dd>
            </Fragment>
          ))}
        </dl>
      </div>
    );
  }
}

const Log: React.FC<LogProps> = ({
  items,
  showStatus,
  amlCtfVersion = "",
  //isNewAmlCtfVersion = false,
}) => {
  const [isItemExpanded, setIsItemExpanded] = useState<{
    [key: string]: boolean;
  }>({});
  useEffect(() => {
    setIsItemExpanded(
      items.reduce((acc, item) => ({ ...acc, [item.id]: false }), {})
    );
  }, [items]);

  const isNewAmlCtfVersion = amlCtfVersion !== "";

  const gridData = items.map((item) => ({
    ...item,
    expanded: isItemExpanded[item.id],
    isNewAmlCtfVersion,
  }));

  function handleExpandChange(e: GridExpandChangeEvent) {
    const dataItem = e.dataItem;
    setIsItemExpanded((state) => ({
      ...state,
      [dataItem.id]: !state[dataItem.id],
    }));
  }
  return (
    <Grid
      className={styles.logGrid}
      expandField="expanded"
      detail={LogDetail}
      onExpandChange={handleExpandChange}
      data={gridData}
    >
      <GridColumn field="email" title="User" />
      {showStatus && <GridColumn field="status" title="Status" />}
      <GridColumn field="date" title="Date" format="{0:dd/MMM/yyyy HH:mm}" />
    </Grid>
  );
};

export default Log;
